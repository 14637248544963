import { orderBy } from 'lodash';
import { computed, makeAutoObservable, runInAction } from 'mobx';
import { getProjectFileDocumentViewerUrl } from '../../api/authenticated/cms/getProjectFileViewerUrl';
import { getProjectFileDownloadUrl } from '../../api/authenticated/cms/getProjectFileDownloadUrl';
import { completeTask } from '../../api/authenticated/tasks/completeTask';
import { getTaskHistory, IHistoryItem } from '../../api/authenticated/tasks/getTaskHistory';
import { getTask, ITask, ITaskFileContainer } from '../../api/authenticated/tasks/getTask';
import { getTaskAttachmentFileDownloadUrls } from '../../api/authenticated/tasks/getTaskAttachmentFileDownloadUrls';
import { AxiosError } from 'axios';
import FilesStore from '../files/FilesStore';
import NavBarSelectorStore from '../tasks/navBarSelector/NavBarSelectorStore';
import LayoutStore from '../layout/LayoutStore';
import { withdrawTask } from '../../api/authenticated/tasks/withdrawTask';

class TaskInformationStore {
  constructor() {
    makeAutoObservable(this, { anyTaskFilesBusy: computed, taskFilesSize: computed }, { autoBind: true });
  }

  public taskId = 0;
  public isLoadingTask = false;
  public isRefresh = false;
  public task: ITask | null = null;
  public message: string | null = null;
  public isLoadingHistory = false;
  public historyItems: IHistoryItem[] = [];
  public taskFilesBusy: { [filedId: number]: boolean } = {};
  public urn = '';
  public token = '';
  public isLoading = false;
  public hasLoaded = false;
  public hasErrorMessage = false;

  public errorCode: number | null = null;
  public errorMessage: string | null = null;

  public async init(taskId: number) {
    runInAction(() => {
      this.taskId = taskId;
      this.task = null;
      this.message = null;
      this.historyItems = [];
    });
    await this.loadTask();
    this.loadHistory();
    NavBarSelectorStore.setSelectedSelectorItem(`projectNumber:${this.task?.projectNumber}`);
  }

  public async loadTask() {
    runInAction(() => {
      this.isLoadingTask = true;
    });
    try {
      const task = await getTask(this.taskId);
      runInAction(() => {
        this.task = task;
      });
    } finally {
      runInAction(() => {
        this.isLoadingTask = false;
      });
    }
  }

  public async loadHistory() {
    runInAction(() => {
      this.isLoadingHistory = true;
    });
    try {
      const historyItems = await getTaskHistory(this.taskId);
      runInAction(() => {
        this.historyItems = orderBy(historyItems, (item) => item.eventDateTime, 'desc');
      });
    } finally {
      runInAction(() => {
        this.isLoadingHistory = false;
      });
    }
  }

  public setMessage(value: string) {
    runInAction(() => {
      this.message = value;
    });
  }

  public async approveTask() {
    if (!this.task) return;
    await completeTask(this.task.id, this.message, true);
    this.refreshTask();
  }

  public async rejectTask() {
    if (!this.task) return;
    await completeTask(this.task.id, this.message, false);
    this.refreshTask();
  }

  public async withdrawTask() {
    if (!this.task) return;
    runInAction(() => {
      this.hasErrorMessage = false;
    });
    try {
      await withdrawTask(this.task.id);
      LayoutStore.displayToast('success', 'The Task was withdrawn successfully.');
    } catch (err) {
      this.setError(err as AxiosError<string>);
      runInAction(() => {
        this.hasErrorMessage = true;
      });
    } finally {
      runInAction(() => {
        if (!this.hasErrorMessage) {
          this.refreshTask();
        }
      });
    }
  }
  private async refreshTask() {
    if (!this.task) return;
    this.loadTask();
    this.loadHistory();
    this.setIsRefresh(true);
  }

  public setIsRefresh(refresh: boolean) {
    runInAction(() => {
      this.isRefresh = refresh;
    });
  }

  public async downloadTaskFile(taskFile: ITaskFileContainer, containerFileId?: number) {
    if (this.taskFilesBusy[taskFile.fileContainerId]) return;

    runInAction(() => {
      this.taskFilesBusy[taskFile.fileContainerId] = true;
    });

    try {
      const file =
        taskFile.containerFiles.find(
          (tf) => (containerFileId && tf.containerFileId === containerFileId) || (!containerFileId && tf.native)
        ) ?? taskFile.containerFiles[0];
      const sharePointReleasedFileId = file?.sharePointReleasedFileId;

      const response = await getProjectFileDownloadUrl({
        projectNumber: this.task!.projectNumber,
        transmittalId: null,
        transmittalMessageId: null,
        downloadFiles: [
          {
            containerFileId: file.containerFileId,
            fileContainerId: taskFile.fileContainerId,
            fileRevisionId: taskFile.fileContainerRevisionId,
            releasedFileId: taskFile.releasedFileContainerId,
            sharePointReleasedFileId: sharePointReleasedFileId ?? null,
          },
        ],
      });
      response.forEach((res) => {
        if (res.url && !res.errorMessage) window.open(res.url);
      });
    } finally {
      runInAction(() => {
        this.taskFilesBusy[taskFile.fileContainerId] = false;
      });
    }
  }

  public async downloadAllTaskFiles() {
    if (!this.task || this.anyTaskFilesBusy) return;

    runInAction(() => {
      this.task?.taskFileContainers.forEach((f) => {
        if (f.fileContainerId === null) return;
        this.taskFilesBusy[f.fileContainerId] = true;
      });
    });
    const allFiles = this.task.taskFileContainers
      .flatMap((m) => m.containerFiles)
      .map((file) => {
        const taskFileContainer = this.task?.taskFileContainers?.find((x) =>
          x.containerFiles?.map((m) => m.containerFileId).includes(file.containerFileId)
        );
        return {
          fileContainerId: taskFileContainer?.fileContainerId ?? null,
          containerFileId: file.containerFileId,
          fileRevisionId: taskFileContainer?.fileContainerRevisionId ?? null,
          releasedFileId: taskFileContainer?.releasedFileContainerId ?? null,
        };
      });

    try {
      const response = await getProjectFileDownloadUrl({
        projectNumber: this.task.projectNumber,
        transmittalId: null,
        transmittalMessageId: null,
        downloadFiles: allFiles,
      });
      if (response)
        response.forEach((res) => {
          if (res.url && !res.errorMessage) window.open(res.url);
        });
    } finally {
      runInAction(() => {
        this.task?.taskFileContainers.forEach((f) => {
          if (!f.fileContainerId) return;
          this.taskFilesBusy[f.fileContainerId] = false;
        });
      });
    }
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public async downloadTaskAttachmentFiles(attachmentFileId?: number) {
    const attachmentFileIds = attachmentFileId ? [attachmentFileId] : this.task?.attachmentFiles.map((x) => x.id) ?? [];
    if (!this.task || !attachmentFileIds.length) return;
    try {
      const urls = await getTaskAttachmentFileDownloadUrls({
        projectNumber: this.task?.projectNumber,
        attachmentFileIds: attachmentFileIds,
        taskId: this.taskId,
      });

      if (urls)
        urls.forEach((url) => {
          if (!url.errorMessage) window.open(url.url);
        });
    } catch (err) {
      this.setError(err as AxiosError<string>);
    }
  }

  public get taskFilesSize(): number {
    if (!this.task || this.anyTaskFilesBusy) return 0;
    return this.task.taskFileContainers.reduce((total, file) => total + file.fileSize, 0);
  }

  public get taskAttachmentFilesSize(): number {
    if (!this.task?.attachmentFiles.length) return 0;
    return this.task.attachmentFiles.reduce((total, file) => total + file.totalSize, 0);
  }

  public async openTaskFile(fileContainer: ITaskFileContainer, containerFileId?: number) {
    if (this.taskFilesBusy[fileContainer.fileContainerId]) return;

    runInAction(() => {
      this.taskFilesBusy[fileContainer.fileContainerId] = true;
    });

    try {
      const file =
        fileContainer.containerFiles.find(
          (tf) => (containerFileId && tf.containerFileId === containerFileId) || (!containerFileId && tf.native)
        ) ?? fileContainer.containerFiles[0];
      const sharePointReleasedFileId = file?.sharePointReleasedFileId;

      const url = await getProjectFileDocumentViewerUrl({
        projectNumber: this.task!.projectNumber,
        fileContainerId: fileContainer.fileContainerId,
        fileContainerRevisionId: fileContainer.fileContainerRevisionId,
        fileId: file.containerFileId ?? null,
        releasedFileContainerId: fileContainer.releasedFileContainerId,
        transmittalId: null,
        transmittalMessageId: null,
        sharePointReleasedFileId: sharePointReleasedFileId ?? null,
      });
      if (url) window.open(url);
    } finally {
      runInAction(() => {
        this.taskFilesBusy[fileContainer.fileContainerId] = false;
      });
    }
  }

  public openFileInfo = async (taskFile: ITaskFileContainer) => {
    const contentSelection = FilesStore.getContentSelection(taskFile.fileContainerStateId);
    FilesStore.setSelectedSection(contentSelection, undefined, false);
    FilesStore.showFileInformation(
      this.mapToFile(taskFile),
      NavBarSelectorStore.selectedItem?.project?.projectNumber ?? this.task!.projectNumber
    );
  };

  public get anyTaskFilesBusy() {
    if (!this.task) return false;
    for (const file of this.task.taskFileContainers) {
      if (this.taskFilesBusy[file.fileContainerId]) return true;
    }
    return false;
  }

  public setTaskFileBusy(fileId: number, isBusy: boolean) {
    this.taskFilesBusy[fileId] = isBusy;
  }

  private readonly mapToFile = (fileContainer: ITaskFileContainer) => {
    const file = fileContainer.containerFiles.some((tf) => tf.native)
      ? fileContainer.containerFiles.find((tf) => tf.native)
      : fileContainer.containerFiles[0];
    return {
      id: fileContainer.fileContainerId,
      title: fileContainer.fileTitle,
      extension: fileContainer.extension,
      originalFilename: file?.originalFilename ?? '',
      fieldValue1: fileContainer.fieldValue1,
      fieldValue2: fileContainer.fieldValue2,
      fieldValue3: fileContainer.fieldValue3,
      fieldValue4: fileContainer.fieldValue4,
      fieldValue5: fileContainer.fieldValue5,
      fieldValue6: fileContainer.fieldValue6,
      fieldValue7: fileContainer.fieldValue7,
      fieldValue8: fileContainer.fieldValue8,
      fieldValue9: fileContainer.fieldValue9,
      fieldValue10: fileContainer.fieldValue10,
      fieldValue11: fileContainer.fieldValue11,
      fieldValue12: fileContainer.fieldValue12,
      fieldValue13: fileContainer.fieldValue13,
      fieldValue14: fileContainer.fieldValue14,
      fieldValue15: fileContainer.fieldValue15,
      fieldValue16: fileContainer.fieldValue16,
      fieldValue17: fileContainer.fieldValue17,
      fieldValue18: fileContainer.fieldValue18,
      fieldValue19: fileContainer.fieldValue19,
      fieldValue20: fileContainer.fieldValue20,
      pattern1: null,
      pattern2: null,
      pattern3: null,
      isAPIMS: false,
      taskTeamId: 0,
      containerFileId: 0,
      uploadedSize: fileContainer.containerFiles.reduce((sum, current) => sum + current.uploadedSize, 0),
      uploadedByUserName: fileContainer.uploadedByUserName,
      modifiedDate: fileContainer.uploadedDate,
      createdDate: fileContainer.createdDate,
      formattedRevision: fileContainer.formattedRevision,
      suitabilityCode: fileContainer.suitabilityCode,
      isSuitabilityRefCode: fileContainer.isSuitabilityRefCode,
      suitabilityTitle: fileContainer.suitabilityTitle,
      fileRevisionId: fileContainer.fileContainerRevisionId,
      inSharePoint: null,
      isLocked: false,
      isLockedByUnknownReason: false,
      lockedReason: null,
      isForgeFile: false,
      taskTypeId: null,
      uploadedByUserId: 0,
      canTaskTeamReview: false,
      canShareReview: false,
      canDeliveryTeamReview: false,
      canPublishReview: false,
      canCollaborate: false,
      canSubmitForge: false,
      canDelete: false,
      canPublished: false,
      hasDocumentViewer: false,
      lastModifiedUserName: null,
      releasedDate: null,
      revision: null,
      suitabilityId: null,
      releasedFileId: fileContainer.releasedFileContainerId,
      deliveryTeamId: null,
      deliveryTeamTitle: fileContainer.deliveryTeamTitle,
      taskTeamTitle: fileContainer.taskTeamTitle,
      inWorkflow: false,
      containerFiles: fileContainer.containerFiles,
    };
  };
}

export default new TaskInformationStore();
