import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { IAuthInstance } from '../models/IAuthInstance';
import { AuthType } from '../models/AuthType';

export class Auth0Instance implements IAuthInstance {
  private readonly domain: string;
  private readonly clientId: string;
  private readonly audience: string;
  private readonly scope: string;
  private instance: Auth0Client | null;

  constructor(domain: string, clientId: string, audience: string, scope: string) {
    this.domain = domain;
    this.clientId = clientId;
    this.audience = audience;
    this.scope = scope;
    this.instance = null;
  }

  getType(): AuthType {
    return 'auth0';
  }

  async init(): Promise<void> {
    this.instance = await createAuth0Client({
      domain: this.domain,
      clientId: this.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: this.audience,
        scope: this.scope,
      },
      useRefreshTokens: true,
      cacheLocation: 'localstorage',
    });
  }

  getConfig<T>(): T {
    return {
      domain: this.domain,
      clientId: this.clientId,
      audience: this.audience,
      scope: this.scope,
    } as T;
  }

  async getInstance<T>(): Promise<T> {
    return this.instance as T;
  }

  async getTokenSilently(scope: string): Promise<string> {
    if (!this.instance) throw new Error('Auth instance is not initialized.');
    const token = await this.instance.getTokenSilently({
      authorizationParams: {
        scope: scope,
      },
    });

    return token;
  }
}
