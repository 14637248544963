import {
  Accordion,
  AccordionPanel,
  Button,
  ComboBox,
  FormInput,
  Grid,
  Table,
} from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IMetadataField, getProjectMetadata } from '../../../api/authenticated/cms/getProjectMetadata';
import { addMetadataField } from '../../../api/authenticated/config/addMetadataField';
import { archiveMetadataField } from '../../../api/authenticated/config/archiveMetadataField';
import { deleteMetadataField } from '../../../api/authenticated/config/deleteMetadataField';
import { MetadataFieldTypeText } from '../../../common/constants/MetadataFieldTypeText';
import { MetaDataFieldTypeEnum, MetadataFieldType } from '../../../common/enums/MetadataFieldType';
import {
  IFileMetadataField,
  IFileMetadataValue,
  IRemoveArchiveMetadataValueModel,
  MetadataFieldValueActionRef,
} from '../../../models/metadataSetting/metadataSettingModels';
import Style from '../../../styles/components/settings/projectManagement/ProjectFileMetadata.module.scss';
import { classNames, validateAlphaNumeric, validateAlphaNumericDashColonComma } from '../../../utils/miscUtils';
import {
  getFieldColumnWStyle,
  getFieldValueColumnWStyle,
  getHeaders,
  tableHeaderKeyValueFields,
} from '../../../utils/projectFileMetadataUtils';
import LayoutStore from '../../layout/LayoutStore';
import ConfirmationModal from '../../shared/ConfirmationModal';
import ErrorModal from '../../shared/ErrorModal';
import PrimaryButton from '../../shared/PrimaryButton';
import PrimaryIconButton from '../../shared/PrimaryIconButton';
import SecondaryButton from '../../shared/SecondaryButton';
import AddProjectFileMetadataValue from './AddProjectFileMetadataValue';
import MetadataFieldActionButton from './MetadataFieldActionButton';
import MetadataFieldValueActionButton from './MetadataFieldValueActionButton';

const maxTitleLength = 30;
const maxMetadataFieldsAllowed = 20;
const maxFieldValueTitleLength = 200;
const maxFieldValueCodeLength = 5;
const listFieldIndexes = Array.from({ length: 20 }, (_, i) => i + 1);
const metadataTypes = [
  {
    id: MetadataFieldType.List.toString(),
    value: MetadataFieldTypeText[MetadataFieldType.List],
  },
  {
    id: MetadataFieldType.UserText.toString(),
    value: MetadataFieldTypeText[MetadataFieldType.UserText],
  },
];

interface IProjectFileMetadataProps {
  projectNumber: string;
}

const ProjectFileMetadata: FC<IProjectFileMetadataProps> = ({ projectNumber }) => {
  const [clonedMetaDataFields, setClonedMetaDataFields] = useState<IFileMetadataField[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showExceededFieldsError, setShowExceededFieldsError] = useState(false);
  const [deleteMetadata, setDeleteMetadata] = useState<IFileMetadataField | null>(null);
  const [archiveMetadata, setArchiveMetadata] = useState<IFileMetadataField | null>(null);
  const valueActionButtonRefs = useRef<MetadataFieldValueActionRef[]>([]);

  const buildNestedFileMetadata = useCallback(
    (
      metaDataFields: IMetadataField[],
      filteredMetaDataFields: IMetadataField[],
      isParentArchived = false
    ): IFileMetadataField[] => {
      if (!filteredMetaDataFields.length) return [];
      return [
        ...filteredMetaDataFields.map((m) => {
          return {
            id: m.id,
            title: m.title,
            metaDataTypeId: m.metaDataTypeId,
            fieldValueIndex: m.fieldValueIndex,
            isNamingConventionField: m.isNamingConventionField,
            dataType: {
              required: m.dataType.required,
              showCode: m.dataType.showCode,
              fieldType: m.dataType.fieldType,
              parentCode: m.dataType.parentCode,
              parentFieldValueIndex: m.dataType.parentFieldValueIndex,
              fieldValues: !m.dataType.fieldValues
                ? []
                : m.dataType.fieldValues.map((f) => {
                    return {
                      code: f.code,
                      title: f.displayText,
                      displayText: '',
                      parentCode: f.parentCode,
                      archived: f.archived,
                      id: 0,
                      isUsed: f.isUsed,
                      isNew: false,
                      tempId: uuidv4(),
                      dataType: {
                        required: false,
                      },
                      errors: {
                        title: '',
                        code: '',
                      },
                    };
                  }),
              nestedMetadataField: buildNestedFileMetadata(
                metaDataFields,
                metaDataFields.filter((f) => f.dataType.parentFieldValueIndex === m.fieldValueIndex),
                m.archived
              ),
            },
            archived: m.archived,
            parentArchived: isParentArchived,
            isUsed: m.isUsed,
            tempId: uuidv4(),
            isUpdate: false,
            errors: {
              title: '',
            },
          };
        }),
      ];
    },
    []
  );

  const loadMetaData = useCallback(async () => {
    if (!projectNumber) return;
    setActivePanels([]);

    const metaDataFields = await getProjectMetadata(projectNumber, true);

    const consolidatedData = [
      ...buildNestedFileMetadata(
        metaDataFields,
        metaDataFields.filter((f) => !f.dataType.parentFieldValueIndex)
      ),
    ];
    setClonedMetaDataFields([...consolidatedData]);
  }, [projectNumber, buildNestedFileMetadata]);

  useEffect(() => {
    loadMetaData();
  }, [loadMetaData]);

  const renderMetadataValue = (
    metadataValue: IFileMetadataValue,
    metadataField: IFileMetadataField,
    isNested: boolean
  ) => {
    const setButtonRef = (el: MetadataFieldValueActionRef) => {
      valueActionButtonRefs.current[metadataValue.tempId] = el;
    };
    return (
      <div
        className={classNames(Style.rowWrapperType, Style.childWrapper, [
          metadataField.archived || metadataValue.archived,
          Style.disableRow,
        ])}
        key={`${metadataValue.code}${metadataValue.title}${metadataValue.parentCode}`}>
        <div
          style={getFieldValueColumnWStyle(editMode, tableHeaderKeyValueFields.field.label, isNested)}
          className={classNames(Style.row, Style.textOverflow)}>
          <span className={Style.childRow}>{metadataValue.title}</span>
        </div>
        <div
          style={getFieldValueColumnWStyle(editMode, tableHeaderKeyValueFields.type.label, isNested)}
          className={classNames(Style.row, Style.textOverflow)}></div>
        <div
          style={getFieldValueColumnWStyle(editMode, tableHeaderKeyValueFields.abbreviation.label, isNested)}
          className={classNames(Style.row, Style.textOverflow)}>
          <span>{metadataValue.code}</span>
        </div>
        <div
          style={getFieldValueColumnWStyle(editMode, tableHeaderKeyValueFields.parentCode.label, isNested)}
          className={classNames(Style.row, Style.textOverflow)}>
          <span>{metadataValue.parentCode}</span>
        </div>

        {editMode && (
          <div className={classNames(Style.row, Style.actionWrapper, Style.textOverflow, Style.archiveButton)}>
            <MetadataFieldValueActionButton
              metadataFieldValue={metadataValue}
              metadataField={metadataField}
              isSaving={isSaving}
              onConfirmArchiveMetadataFieldValue={onConfirmArchiveMetadataFieldValue}
              onConfirmDeleteMetadataFieldValue={onConfirmDeleteMetadataFieldValue}
              ref={setButtonRef}
            />
          </div>
        )}
      </div>
    );
  };

  const renderMetadataValueFields = (
    metadataField: IFileMetadataField,
    metadataValue: IFileMetadataValue,
    isNested: boolean
  ) => {
    let parentFieldValues: IFileMetadataValue[] | undefined = undefined;
    if (metadataValue.isNew && isNested) {
      parentFieldValues = clonedMetaDataFields.find(
        (r: IFileMetadataField) => r.fieldValueIndex === metadataField.dataType.parentFieldValueIndex
      )?.dataType.fieldValues;
    }

    return metadataValue.isNew
      ? addNewMetadataFieldValues(metadataValue, metadataField, parentFieldValues, isNested)
      : renderMetadataValue(metadataValue, metadataField, isNested);
  };

  const renderNewMetadataValueButton = (isEdit: boolean, metadataField: IFileMetadataField, isNested: boolean) => {
    if (metadataField.archived) return;
    return metadataField.dataType.fieldType === MetadataFieldType.List && isEdit ? (
      <div>
        <Button
          type="text"
          cssClass={Style.addNewButton}
          icon="add"
          size="medium"
          label="Add New Metadata Value"
          onClick={() => handleAddNewMetadataValue(metadataField.tempId, isNested)}
        />
      </div>
    ) : (
      ''
    );
  };

  const renderFileMetadataFields = (clonedMetaDataFields: IFileMetadataField[], isNested: boolean) => {
    const lastIndex = clonedMetaDataFields.length - 1;
    return clonedMetaDataFields.map((m, i) => (
      <div key={m.tempId}>
        <AccordionPanel
          cssHeaderClass={classNames([isNested && i === lastIndex, Style.lastNestedRowWrapper])}
          cssContentClass={classNames([isNested && i === lastIndex, Style.lastNestedRowWrapper])}
          disabled={m.dataType.fieldType !== MetadataFieldType.List}
          label={
            <div className={classNames(Style.rowWrapperType, [m.archived, Style.disableRow])}>
              {m.id ? (
                <div
                  style={getFieldColumnWStyle(editMode, tableHeaderKeyValueFields.field.label, isNested)}
                  className={classNames(Style.row, Style.textOverflow)}>
                  <span>{m.title}</span>
                  {m.dataType.required && <span className={Style.required}>*</span>}
                </div>
              ) : (
                <div
                  style={getFieldColumnWStyle(editMode, tableHeaderKeyValueFields.field.label, isNested)}
                  className={Style.inputWrapper}>
                  <FormInput
                    value={m.title}
                    placeholder="Enter Metadata Field"
                    error={m.errors.title}
                    onChange={(value) => {
                      handleSetMetadataField(
                        value,
                        m.tempId,
                        tableHeaderKeyValueFields.field.name,
                        tableHeaderKeyValueFields.field.label
                      );
                    }}
                  />
                  {!m.errors.title && (
                    <span className={Style.charactersLeft}>
                      {maxTitleLength - (m.title?.length ?? 0)} characters left
                    </span>
                  )}
                </div>
              )}

              {m.id ? (
                <div
                  style={getFieldColumnWStyle(editMode, tableHeaderKeyValueFields.type.label, isNested)}
                  className={classNames(Style.row, Style.textOverflow)}>
                  <span className={Style.fieldType}>{MetadataFieldTypeText[m.dataType.fieldType]}</span>
                </div>
              ) : (
                <div
                  style={getFieldColumnWStyle(editMode, tableHeaderKeyValueFields.type.label, isNested)}
                  className={Style.inputWrapper}>
                  <ComboBox
                    showIcon={false}
                    options={metadataTypes}
                    disabled={m.dataType.fieldValues.some((value) => value.tempId)}
                    selected={m.dataType.fieldType}
                    onSelect={(value) => {
                      if (!value) return;
                      handleSetMetadataFieldType(value.id.toString(), m.tempId);
                    }}
                  />
                </div>
              )}

              <div
                style={getFieldColumnWStyle(editMode, tableHeaderKeyValueFields.abbreviation.label, isNested)}
                className={classNames(Style.row, Style.textOverflow)}></div>
              <div
                style={getFieldColumnWStyle(editMode, tableHeaderKeyValueFields.parentCode.label, isNested)}
                className={classNames(Style.row, Style.textOverflow)}></div>
              {editMode && (
                <div className={classNames(Style.row, Style.actionWrapper, Style.textOverflow, Style.archiveButton)}>
                  <MetadataFieldActionButton
                    metadataField={m}
                    onArchiveMetadata={() => setArchiveMetadata(m)}
                    onDeleteMetadata={() => setDeleteMetadata(m)}></MetadataFieldActionButton>
                </div>
              )}
            </div>
          }
          panelId={m.tempId}>
          <div className={Style.contentWrapper}>
            {m.dataType.fieldValues.map((s: IFileMetadataValue) => (
              <div key={s.tempId}>{renderMetadataValueFields(m, s, isNested)}</div>
            ))}
            {renderFileMetadataFields(m.dataType.nestedMetadataField ?? [], true)}
          </div>
          {renderNewMetadataValueButton(editMode, m, isNested)}
        </AccordionPanel>
      </div>
    ));
  };

  const handleMetadataValueRemoved = (
    fieldValue: IFileMetadataValue,
    metadataFileTempId: string,
    isNested: boolean
  ) => {
    let current: IFileMetadataField | undefined;
    current = clonedMetaDataFields.find((f) => f.tempId === metadataFileTempId);
    if (!current && isNested) {
      current = clonedMetaDataFields
        .filter((x) => x.dataType.nestedMetadataField)
        .flatMap((m) => (!m.dataType.nestedMetadataField ? [] : m.dataType.nestedMetadataField))
        .find((r) => r.tempId === metadataFileTempId);
    }

    if (!current) return;
    current.dataType.fieldValues = [...current.dataType.fieldValues.filter((v) => v.tempId !== fieldValue.tempId)];
    current.isUpdate = true;

    handleSetMetadataField(
      current.title,
      current.tempId,
      tableHeaderKeyValueFields.field.name,
      tableHeaderKeyValueFields.field.label
    );
    setClonedMetaDataFields([...clonedMetaDataFields]);
    validateMetadataValues(current, isNested, fieldValue);
  };

  const handleMetadataValueChanged = (
    fieldValue: IFileMetadataValue,
    metadataFileTempId: string,
    isNested: boolean
  ) => {
    let current: IFileMetadataField | undefined;
    current = clonedMetaDataFields.find((f) => f.tempId === metadataFileTempId);
    if (!current && isNested) {
      current = clonedMetaDataFields
        .filter((x) => x.dataType.nestedMetadataField)
        .flatMap((m) => (!m.dataType.nestedMetadataField ? [] : m.dataType.nestedMetadataField))
        .find((r) => r.tempId === metadataFileTempId);
    }
    if (!current) return;

    const fieldValueToUpdate = current.dataType.fieldValues.find((v) => v.tempId === fieldValue.tempId);
    if (!fieldValueToUpdate) return;
    fieldValueToUpdate.code = fieldValue.code;
    fieldValueToUpdate.title = fieldValue.title;
    fieldValueToUpdate.parentCode = fieldValue.parentCode;
    fieldValueToUpdate.errors = fieldValue.errors;
    current.isUpdate = true;
    setClonedMetaDataFields([...clonedMetaDataFields]);

    validateMetadataValues(current, isNested, fieldValue);
  };

  const validateMetadataValues = (
    metadataFieldValues: IFileMetadataField | undefined,
    isNested: boolean,
    fieldValue: IFileMetadataValue
  ) => {
    metadataFieldValues?.dataType.fieldValues
      .filter((x) => x.isNew)
      .map((id) => {
        id.errors.title = validateMetadataValueInput(
          metadataFieldValues,
          id.tempId,
          id.title,
          tableHeaderKeyValueFields.value.label,
          maxFieldValueTitleLength
        );
        id.errors.code = validateMetadataValueInput(
          metadataFieldValues,
          id.tempId,
          id.code,
          tableHeaderKeyValueFields.abbreviation.label,
          maxFieldValueCodeLength
        );
        if (isNested && fieldValue.isNew) {
          id.errors.parentCode = validateParentCodeValue(fieldValue.parentCode);
        }
      });

    setClonedMetaDataFields([...clonedMetaDataFields]);
  };

  const validateMetadataValueInput = (
    metadataFiled: IFileMetadataField,
    tempId: string,
    value: string,
    label: string,
    maxLength?: number
  ) => {
    const error = textCommonRulesValidate(value, label, maxLength);
    if (error.length) return error;

    if (
      value &&
      metadataFiled.dataType.fieldValues.some(
        (t) =>
          t.tempId !== tempId &&
          (label === tableHeaderKeyValueFields.value.label
            ? t.title.toLowerCase() === value.toLowerCase()
            : t.code.toLowerCase() === value.toLowerCase())
      )
    )
      return `${label} already exists`;

    return '';
  };

  const validateParentCodeValue = (parentCode: string) => {
    if (!parentCode) return `* is a required field`;
    return '';
  };
  const addNewMetadataFieldValues = (
    metadataValue: IFileMetadataValue,
    metadataField: IFileMetadataField,
    fieldValues: IFileMetadataValue[] | undefined,
    isNested: boolean
  ) => {
    return (
      <AddProjectFileMetadataValue
        value={metadataValue}
        fieldValues={fieldValues}
        isEditMode={editMode}
        isNested={isNested}
        onMetadataValueChanged={(metadataValue) =>
          handleMetadataValueChanged(metadataValue, metadataField.tempId, isNested)
        }
        onMetadataValueRemoved={(metadataValue) =>
          handleMetadataValueRemoved(metadataValue, metadataField.tempId, isNested)
        }
      />
    );
  };

  const renderFileMetadataFieldsWrapper = () => {
    return (
      <Accordion
        verticalPanelGap="24px"
        headingBgColour="#F2F2F2"
        headingFontColour="#000000"
        panelBgColour="#FAFAFA"
        headingIconColour="#f37021"
        activePanelIds={activePanels}
        onPanelToggle={(m) => handleAccordionToggle(m)}
        cssClass={Style.fileMetadataAccordion}>
        {renderFileMetadataFields(clonedMetaDataFields, false)}
      </Accordion>
    );
  };

  const handleAccordionToggle = (id: string) => {
    const newIds = new Set<string>(activePanels);
    newIds.has(id) ? newIds.delete(id) : newIds.add(id);
    setActivePanels(Array.from(newIds));
  };

  const handleAddNewMetadataField = () => {
    const subMetaDataIndexes = clonedMetaDataFields
      .filter((x) => x.metaDataTypeId === MetaDataFieldTypeEnum.TucanaMetaDataField && x.dataType.nestedMetadataField)
      .flatMap((m) => (!m.dataType.nestedMetadataField ? [] : m.dataType.nestedMetadataField))
      .map((x) => x.fieldValueIndex);
    if (
      subMetaDataIndexes.length +
        clonedMetaDataFields.filter((f) => f.metaDataTypeId === MetaDataFieldTypeEnum.TucanaMetaDataField).length ===
      maxMetadataFieldsAllowed
    ) {
      setShowExceededFieldsError(true);
      return;
    }

    const availableIndexes = listFieldIndexes.filter(
      (el) =>
        !clonedMetaDataFields
          .map((x) => x.fieldValueIndex)
          .concat(subMetaDataIndexes)
          .includes(el)
    );

    const newMetadataField = {
      id: 0,
      title: '',
      metaDataTypeId: MetaDataFieldTypeEnum.TucanaMetaDataField,
      fieldValueIndex: availableIndexes[0] ?? 0,
      isNamingConventionField: false,
      dataType: {
        required: false,
        showCode: true,
        fieldType: MetadataFieldType.List,
        parentFieldValueIndex: null,
        fieldValues: [],
        parentCode: null,
      },
      isTemp: true,
      isUpdate: true,
      tempId: uuidv4(),
      archived: false,
      parentArchived: false,
      isUsed: false,
      errors: {
        title: '',
      },
    };
    setClonedMetaDataFields((cloned) => {
      return [...cloned, newMetadataField];
    });
    handleAccordionToggle(newMetadataField.tempId);
  };

  const handleAddNewMetadataValue = (tempFieldId: string, isNested: boolean) => {
    let metadataField: IFileMetadataField | undefined;
    metadataField = clonedMetaDataFields.find((r: IFileMetadataField) => r.tempId === tempFieldId);
    if (!metadataField && isNested) {
      metadataField = clonedMetaDataFields
        .filter((x) => x.dataType.nestedMetadataField)
        .flatMap((m) => (!m.dataType.nestedMetadataField ? [] : m.dataType.nestedMetadataField))
        .find((r) => r.tempId === tempFieldId);
    }
    if (!metadataField) return;
    const newMetadataValue: IFileMetadataValue = {
      code: '',
      title: '',
      displayText: '',
      parentCode: '',
      isNew: true,
      isUsed: false,
      archived: false,
      tempId: uuidv4(),
      dataType: {
        required: true,
      },
      errors: { title: '', code: '', parentCode: '* is a required field' },
    };
    metadataField.dataType.fieldValues = [...metadataField.dataType.fieldValues, newMetadataValue];
    handleSetMetadataField(
      metadataField.title,
      metadataField.tempId,
      tableHeaderKeyValueFields.field.name,
      tableHeaderKeyValueFields.field.label
    );
    setClonedMetaDataFields([...clonedMetaDataFields]);
  };

  const validateInput = (tempId: string, value: string, label: string, maxLength?: number) => {
    const error = textCommonRulesValidate(value, label, maxLength);
    if (error.length) return error;

    if (value && clonedMetaDataFields.some((t) => t.tempId !== tempId && t.title.toLowerCase() === value.toLowerCase()))
      return `${label} already exists`;

    return '';
  };

  const textCommonRulesValidate = (value: string, label: string, maxLength?: number) => {
    value = value?.trim() ?? '';
    if (!value) return `* is a required field`;
    if (
      label === tableHeaderKeyValueFields.value.label
        ? !validateAlphaNumericDashColonComma(value)
        : !validateAlphaNumeric(value)
    )
      return label === tableHeaderKeyValueFields.value.label ? `Invalid ${label}` : `Only alphanumeric`;
    const headerLabel = label === tableHeaderKeyValueFields.field.label ? `Metadata Field` : `${label}`;
    if (maxLength && value.length > maxLength) return `${headerLabel} can not exceed ${maxLength} characters.`;
    return '';
  };

  const handleSetMetadataField = (value: string, tempId: string, fieldName: string, label: string) => {
    const current = clonedMetaDataFields.find((f) => f.tempId === tempId);
    if (!current) return;

    current[fieldName] = value;
    current.errors[fieldName] = validateInput(tempId, value, label, maxTitleLength);
    if (!current.errors[fieldName].length) {
      validateFieldValues(current);
    }
    setClonedMetaDataFields([...clonedMetaDataFields]);
  };

  const handleSetMetadataFieldType = (value: string, tempId: string) => {
    const current = clonedMetaDataFields.find((f) => f.tempId === tempId);
    if (!current) return;

    current.dataType.fieldType = value;
    handleSetMetadataField(
      current.title,
      current.tempId,
      tableHeaderKeyValueFields.field.name,
      tableHeaderKeyValueFields.field.label
    );
    setClonedMetaDataFields([...clonedMetaDataFields]);
    handleAccordionToggleTypeChange(current.tempId, current.dataType.fieldType);
  };

  const handleAccordionToggleTypeChange = (id: string, fieldType: string) => {
    const newIds = new Set<string>(activePanels);
    fieldType === MetadataFieldType.List ? newIds.add(id) : newIds.has(id) && newIds.delete(id);
    setActivePanels(Array.from(newIds));
  };

  const validateFieldValues = (current: IFileMetadataField) => {
    if (
      current.dataType.fieldType === MetadataFieldType.List &&
      !current.dataType.fieldValues.length &&
      !current.errors.title.length
    ) {
      current.errors.title = 'Need to have a metadata value.';
    } else {
      current.errors.title = '';
    }
  };

  const getCurrentMetadataField = (metadataField: IFileMetadataField) => {
    if (!metadataField.dataType.parentFieldValueIndex) {
      return clonedMetaDataFields.find((f) => f.tempId === metadataField.tempId) ?? null;
    }
    return (
      clonedMetaDataFields
        .find((f) => metadataField.dataType.parentFieldValueIndex === f.fieldValueIndex)
        ?.dataType.nestedMetadataField?.find((n) => n.tempId === metadataField.tempId) ?? null
    );
  };

  const cleanUpArchiveDelete = () => {
    setIsSaving(false);
    setDeleteMetadata(null);
    setArchiveMetadata(null);
  };

  const onConfirmArchiveMetadataField = async (metadataField: IFileMetadataField | null) => {
    setIsSaving(true);
    try {
      if (!metadataField) return;
      const current = getCurrentMetadataField(metadataField);
      if (!current) return;

      await archiveMetadataField({
        projectNumber: projectNumber,
        metadataFieldId: metadataField.id,
        archive: !metadataField.archived,
      });

      current.archived = !current.archived;
      current.dataType.fieldValues = current.dataType.fieldValues.map((x) => ({
        ...x,
        archived: metadataField.archived,
      }));

      // update related child metadata field.
      const updateNested =
        current.dataType.nestedMetadataField?.map((x) => {
          x.archived = metadataField.archived;
          x.parentArchived = metadataField.archived;
          x.dataType.fieldValues = x.dataType.fieldValues.map((v) => ({ ...v, archived: metadataField.archived }));
          return x;
        }) ?? [];

      current.dataType.nestedMetadataField = [...updateNested];

      if (!metadataField.id) return;
      setClonedMetaDataFields([...clonedMetaDataFields]);
      LayoutStore.displayToast(
        'success',
        `Project Metadata field ${metadataField.title} have been successfully ${
          metadataField.archived ? 'unarchive' : 'archive'
        }.`
      );
    } catch {
      LayoutStore.displayToast(
        'error',
        `Metadata field can not be ${metadataField?.archived ? 'unarchive' : 'archive'}.`
      );
    } finally {
      cleanUpArchiveDelete();
    }
  };

  const onConfirmDeleteMetadataField = async (metadataField: IFileMetadataField | null) => {
    if (!metadataField) return;

    const deleteTemporaryNestedFromParentField = (parentField: IFileMetadataField) => {
      if (parentField.fieldValueIndex === metadataField.dataType.parentFieldValueIndex) {
        parentField.dataType.nestedMetadataField = [
          ...(parentField.dataType.nestedMetadataField?.filter((nested) => nested.tempId !== metadataField.tempId) ??
            []),
        ];
      }
      return parentField;
    };

    const removeItemFromMemory = () => {
      if (!metadataField.dataType.parentFieldValueIndex) {
        setClonedMetaDataFields([...clonedMetaDataFields.filter((f) => f.tempId !== metadataField.tempId)]);
      } else {
        setClonedMetaDataFields([
          ...clonedMetaDataFields.map((f) => {
            return deleteTemporaryNestedFromParentField(f);
          }),
        ]);
      }
      setClonedMetaDataFields([...clonedMetaDataFields.filter((f) => f.tempId !== metadataField.tempId)]);
      cleanUpArchiveDelete();
    };

    setIsSaving(true);

    if (!metadataField.id) {
      removeItemFromMemory();
      return;
    }

    try {
      await deleteMetadataField(projectNumber, metadataField.id);
      removeItemFromMemory();
      LayoutStore.displayToast(
        'success',
        `Project Metadata field ${metadataField.title} have been successfully deleted.`
      );
    } catch {
      LayoutStore.displayToast('error', 'Metadata field can not be delete.');
    } finally {
      cleanUpArchiveDelete();
    }
  };

  const onConfirmArchiveMetadataFieldValue = async (archiveValueModel: IRemoveArchiveMetadataValueModel) => {
    const element = valueActionButtonRefs.current[archiveValueModel.metadataValue.tempId];
    const action = archiveValueModel?.metadataValue.archived ? 'unarchive' : 'archive';
    setIsSaving(true);
    try {
      const currentField = getCurrentMetadataField(archiveValueModel.metadataField);
      if (!currentField) {
        element.closeModal();
        return;
      }

      const currentMetadataValues = Object.assign(currentField.dataType.fieldValues);

      await addMetadataField(projectNumber, [
        {
          id: currentField.id,
          title: currentField.title,
          fieldValueIndex: currentField.fieldValueIndex,
          dataType: {
            required: currentField.dataType.required,
            showCode: currentField.dataType.showCode,
            fieldType: currentField.dataType.fieldType,
            fieldValues: currentMetadataValues.map((v: IFileMetadataValue) => {
              if (v.tempId !== archiveValueModel.metadataValue.tempId) return v;
              v.archived = !v.archived;
              return v;
            }),
            parentCode: currentField.dataType.parentCode,
            parentFieldValueIndex: currentField.dataType.parentFieldValueIndex,
          },
        },
      ]);

      const currentValue = currentField.dataType.fieldValues.find(
        (x) => x.tempId === archiveValueModel?.metadataValue.tempId
      );
      if (currentValue) currentValue.archived = !currentValue.archived;
      setClonedMetaDataFields([...clonedMetaDataFields]);
      LayoutStore.displayToast(
        'success',
        `Project Metadata value ${archiveValueModel.metadataValue.title} have been successfully ${action}.`
      );
    } catch {
      LayoutStore.displayToast('error', `Metadata value can not be ${action}.`);
    } finally {
      setIsSaving(false);

      if (element) {
        element.closeModal();
      }
    }
  };

  const onConfirmDeleteMetadataFieldValue = async (deleteValueModel: IRemoveArchiveMetadataValueModel) => {
    const element = valueActionButtonRefs.current[deleteValueModel.metadataValue.tempId];
    setIsSaving(true);
    try {
      const currentField = getCurrentMetadataField(deleteValueModel.metadataField);
      if (!currentField) {
        element.closeModal();
        return;
      }

      // only call api when value existed in system.
      if (!deleteValueModel.metadataValue.isNew) {
        await addMetadataField(projectNumber, [
          {
            id: currentField.id,
            title: currentField.title,
            fieldValueIndex: currentField.fieldValueIndex,
            dataType: {
              required: currentField.dataType.required,
              showCode: currentField.dataType.showCode,
              fieldType: currentField.dataType.fieldType,
              fieldValues: [
                ...currentField.dataType.fieldValues.filter((x) => x.tempId !== deleteValueModel.metadataValue.tempId),
              ],
              parentCode: currentField.dataType.parentCode,
              parentFieldValueIndex: currentField.dataType.parentFieldValueIndex,
            },
          },
        ]);
      }

      // close modal before unmounted component.
      element.closeModal();
      currentField.dataType.fieldValues = currentField.dataType.fieldValues.filter(
        (x) => x.tempId !== deleteValueModel.metadataValue.tempId
      );
      setClonedMetaDataFields([...clonedMetaDataFields]);
      LayoutStore.displayToast(
        'success',
        `Project Metadata value ${deleteValueModel.metadataValue.title} have been successfully deleted.`
      );
    } catch {
      element.closeModal();
      LayoutStore.displayToast('error', `Metadata value ${deleteValueModel?.metadataValue.title} can not be delete.`);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = async () => {
    await loadMetaData();
    setEditMode(false);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const requests = clonedMetaDataFields
        .filter((f) => !f.id || f.isUpdate)
        .map((m) => {
          return {
            id: m.id,
            title: m.title,
            fieldValueIndex: m.fieldValueIndex,
            dataType: {
              required: m.dataType.required,
              showCode: m.dataType.showCode,
              fieldType: m.dataType.fieldType,
              fieldValues: m.dataType.fieldValues,
              parentCode: m.dataType.parentCode,
              parentFieldValueIndex: m.dataType.parentFieldValueIndex,
            },
          };
        });
      const nestedMetadataFields = clonedMetaDataFields
        .filter((x) => x.dataType.nestedMetadataField)
        .flatMap((m) => (!m.dataType.nestedMetadataField ? [] : m.dataType.nestedMetadataField))
        .filter((f) => !f.id || f.isUpdate)
        .map((m) => {
          return {
            id: m.id,
            title: m.title,
            fieldValueIndex: m.fieldValueIndex,
            dataType: {
              required: m.dataType.required,
              showCode: m.dataType.showCode,
              fieldType: m.dataType.fieldType,
              fieldValues: m.dataType.fieldValues,
              parentCode: m.dataType.parentCode,
              parentFieldValueIndex: m.dataType.parentFieldValueIndex,
            },
          };
        });
      await addMetadataField(projectNumber, [...requests, ...nestedMetadataFields]);
      await loadMetaData();
      LayoutStore.displayToast('success', 'Project Metadata Fields have been successfully saved.');
    } catch {
      handleCancel();
      LayoutStore.displayToast('error', 'Project Metadata Fields can not be saved.');
    } finally {
      setIsSaving(false);
      setEditMode(false);
    }
  };

  const renderActions = () => {
    return (
      <>
        {editMode ? (
          <div className={Style.actionButtonsWrapper}>
            <SecondaryButton onClick={handleCancel} disabled={isSaving}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={handleSave} loading={isSaving} disabled={hasErrors}>
              Save
            </PrimaryButton>
          </div>
        ) : (
          <PrimaryIconButton
            icon="edit"
            size="medium"
            className={Style.actionButton}
            onClick={() => setEditMode(true)}></PrimaryIconButton>
        )}
      </>
    );
  };

  const hasErrors = useMemo(() => {
    let inValidFieldValue = false;
    clonedMetaDataFields.forEach((field) => {
      inValidFieldValue = field.dataType.fieldValues.some((value) => {
        return value.errors.title || (!value.title && value.tempId);
      });

      inValidFieldValue =
        !field.id &&
        field.dataType.fieldType === MetadataFieldTypeText[MetadataFieldType.List] &&
        !field.dataType.fieldValues.length;

      if (inValidFieldValue) return;
    });
    return (
      inValidFieldValue ||
      clonedMetaDataFields.some((field) =>
        field.dataType.fieldValues.some((value) => value.errors.code || (!value.code && value.tempId))
      ) ||
      clonedMetaDataFields.some((field) => field.errors.title || (!field.id && !field.title)) ||
      clonedMetaDataFields
        .flatMap((m) => (!m.dataType.nestedMetadataField ? [] : m.dataType.nestedMetadataField))
        .flatMap((field) =>
          field.dataType.fieldValues
            .filter((x) => x.isNew)
            .map((value) => {
              return validateParentCodeValue(value.parentCode);
            })
        )
        .some((x) => !!x) ||
      clonedMetaDataFields
        .flatMap((m) => (!m.dataType.nestedMetadataField ? [] : m.dataType.nestedMetadataField))
        .some((field) =>
          field.dataType.fieldValues.some(
            (value) => value.isNew && (value.errors.title || value.errors.code || value.errors.parentCode)
          )
        )
    );
  }, [clonedMetaDataFields]);

  return (
    <>
      <Grid row md={12} cssClass={Style.gridGroupWrapper}>
        <div className={classNames(Style.header, Style.settingGroup)}>
          <span>Files Metadata</span>
          {renderActions()}
        </div>
        <p>
          Add new fields or values and archive optional fields for files. To alter the filename for this project, or to
          set mandatory metadata fields, please contact support{' '}
          <a
            href="https://aurecondigital.atlassian.net/servicedesk/customer/portal/27"
            target="_blank"
            rel="noreferrer">
            here
          </a>
          {`.`}
        </p>
      </Grid>
      <Grid item md={12} cssClass={Style.gridTableWrapper}>
        <Table headers={getHeaders(editMode)} />
        <div className={Style.contentWrapper}>{renderFileMetadataFieldsWrapper()}</div>

        {editMode && (
          <div>
            <Button
              type="text"
              cssClass={Style.addNewButton}
              icon="add"
              size="medium"
              label="Add New Metadata Field"
              onClick={handleAddNewMetadataField}
            />
          </div>
        )}
      </Grid>

      {showExceededFieldsError && (
        <ErrorModal
          header={'Error adding field'}
          errorMessage={`You can only add up to ${maxMetadataFieldsAllowed} metadata fields in a project`}
          closeModal={() => setShowExceededFieldsError(false)}
          errorCode={null}></ErrorModal>
      )}

      <ConfirmationModal
        showModal={deleteMetadata !== null}
        heading="Delete metadata field"
        message={`This action will delete this metadata field, along with all the metadata values under it and can not be undone. Are you sure you want to delete ${deleteMetadata?.title}?`}
        confirmText="Yes"
        cancelText="No"
        loading={isSaving}
        onConfirm={() => onConfirmDeleteMetadataField(deleteMetadata)}
        onCancel={() => setDeleteMetadata(null)}></ConfirmationModal>

      <ConfirmationModal
        showModal={archiveMetadata !== null}
        heading={`${archiveMetadata?.archived ? 'Unarchive' : 'Archive'} metadata field`}
        message={`This action will ${
          archiveMetadata?.archived ? 'unarchive' : 'archive'
        } this metadata field. Are you sure you want to ${archiveMetadata?.archived ? 'unarchive' : 'archive'} ${
          archiveMetadata?.title
        }?`}
        confirmText="Yes"
        cancelText="No"
        loading={isSaving}
        onConfirm={() => onConfirmArchiveMetadataField(archiveMetadata)}
        onCancel={() => setArchiveMetadata(null)}></ConfirmationModal>
    </>
  );
};

export default observer(ProjectFileMetadata);
