import { MetaDataFieldTypeEnum } from '../../../common/enums/MetadataFieldType';
import { serverAxios } from '../api';

export interface IFileMetadataFieldValue {
  code: string;
  title: string;
  parentCode: string;
  orderNumber?: number;
  archived: boolean;
  isUsed: boolean;
  displayText: string;
}

export interface IMetadataFieldType {
  fieldType: string;
  required: boolean;
  showCode: boolean;
  parentCode: string | null;
  parentFieldValueIndex: number | null;
  maxLength: number | null;
  fieldValues: IFileMetadataFieldValue[];
}

export interface IMetadataField {
  id: number;
  fieldValueIndex: number;
  title: string;
  dataType: IMetadataFieldType;
  metaDataTypeId: MetaDataFieldTypeEnum;
  isNamingConventionField: boolean;
  description: string | null;
  isUsed: boolean;
  archived: boolean;
}

export async function getProjectMetadata(
  projectNumber: string,
  checkInUsed: boolean,
  abortSignal?: AbortSignal
): Promise<IMetadataField[]> {
  const result = await serverAxios.get<{ metadataFields: IMetadataField[] }>('api/cms/metadata', {
    params: { projectNumber, checkInUsed },
    signal: abortSignal,
  });
  return result.data.metadataFields;
}
